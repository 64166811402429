import {
  Alert,
  Badge,
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  Flex,
  Grid,
  GridItem,
  Link,
  Spinner,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from '@chakra-ui/react'
import dayjs from 'dayjs'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import {
  BsClockFill,
  BsCurrencyExchange,
  BsFilePdfFill,
  BsFileZip,
} from 'react-icons/bs'
import { FaFileExcel, FaMoneyBillWave } from 'react-icons/fa'
import {
  MdAttachment,
  MdBookmark,
  MdCalendarToday,
  MdLocationOn,
  MdMonetizationOn,
  MdWarningAmber,
} from 'react-icons/md'
import { useNavigate, useParams } from 'react-router'
import { useAuthUser } from 'use-eazy-auth'
import BreadCrumb from '../../components/BreadCrumb'
import HeaderActionsPage from '../../components/HeaderActionsPage'
import ModalLogs from '../../components/ModalLogs'
import {
  useProgetto,
  useUpdateBloccoData,
  useUpdateBloccoFinale,
  useUpdateInserimentoBloccato,
} from '../../hooks/progetti'
import {
  useCreateProgettoPreferito,
  useDeleteProgettoPreferito,
} from '../../hooks/progetti-preferiti'
import { useCanCreateProgetti } from '../../permissions'
import createStringLink, { numberFormatter } from '../../utils'
import ModalBloccoData from './ModalBloccoData'
import ModalBloccoFinale from './ModalBloccoFinale'
import ModalBloccoProgetto from './ModalBloccoProgetto'
import ModalLogsMovements from '../../components/ModalLogsMovements'

export default function Progetto() {
  const { id } = useParams()
  const { data: progetto, refetch } = useProgetto(Number(id!))
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()
  const userCanCreateProgetti = useCanCreateProgetti()
  const createPreferito = useCreateProgettoPreferito()
  const deletePreferito = useDeleteProgettoPreferito()

  const {
    isOpen: isOpenBloccoProgetto,
    onOpen: onOpenBloccoProgetto,
    onClose: onCloseBloccoProgetto,
    onToggle: onToggleBloccoProgetto,
  } = useDisclosure()

  const {
    isOpen: isOpenBloccoFinale,
    onOpen: onOpenBloccoFinale,
    onClose: onCloseBloccoFinale,
    onToggle: onToggleBloccoFinale,
  } = useDisclosure()

  const {
    isOpen: isOpenBloccoData,
    onOpen: onOpenBloccoData,
    onClose: onCloseBloccoData,
    onToggle: onToggleBloccoData,
  } = useDisclosure()

  const {
    isOpen: isOpenLogs,
    onOpen: onOpenLogs,
    onClose: onCloseLogs,
    onToggle: onToggleLogs,
  } = useDisclosure()

  const {
    isOpen: isOpenLogsMovimentiEliminati,
    onOpen: onOpenLogsMovimentiEliminati,
    onClose: onCloseLogsMovimentiEliminati,
    onToggle: onToggleLogsMovimentiEliminati,
  } = useDisclosure()

  const updateInserimentoBloccato = useUpdateInserimentoBloccato(Number(id!))
  const updateBloccoFinale = useUpdateBloccoFinale(Number(id!))
  const updateBloccoData = useUpdateBloccoData(Number(id!))

  const breadCrumbsItems = useMemo(() => {
    return [
      {
        label: t('projects'),
        link: '/projects',
      },
      {
        label: progetto?.codice ?? '',
      },
    ]
  }, [t, progetto])

  const createdAt = useMemo(() => {
    if (progetto?.logs) {
      const date = progetto?.logs.find(
        (log) => log.action === 'create'
      )?.timestamp
      return date ? dayjs(date).format('DD/MM/YYYY HH:mm') : ''
    }
  }, [progetto])

  const updatedAt = useMemo(() => {
    if (progetto?.logs) {
      const date = progetto?.logs[0]?.timestamp
      return date ? dayjs(date).format('DD/MM/YYYY HH:mm') : ''
    }
  }, [progetto])

  const updateBy = useMemo(() => {
    if (progetto?.logs && progetto.logs.length > 0) {
      if (progetto?.logs[0]?.actor?.name && progetto?.logs[0]?.actor?.email) {
        return (
          progetto?.logs[0]?.actor?.name +
          ' (' +
          progetto?.logs[0]?.actor?.email +
          ')'
        )
      } else {
        return ''
      }
    }
  }, [progetto])

  const { user } = useAuthUser()

  return (
    <Box width={'100%'} pt={67} bg={'#F8F8F8'}>
      <Flex direction={'column'}>
        <HeaderActionsPage
          sticky
          breadCrumbs={
            <Box display={'flex'}>
              <BreadCrumb items={breadCrumbsItems} />
              {progetto?.inserimento_bloccato && (
                <Badge
                  ml={3}
                  borderRadius={'md'}
                  colorScheme={'red'}
                  fontSize={12}
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'center'}
                  px={2}
                  fontWeight={500}
                >
                  {t('blocked')}
                </Badge>
              )}
              {progetto?.data_blocco_compentenza && (
                <Badge
                  ml={3}
                  borderRadius={'md'}
                  colorScheme={'red'}
                  fontSize={12}
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'center'}
                  px={2}
                  fontWeight={500}
                >
                  {t('block_to_date')}{' '}
                  {dayjs(progetto?.data_blocco_compentenza).format(
                    'DD/MM/YYYY'
                  )}
                </Badge>
              )}
              {progetto?.data_blocco_finale && (
                <Badge
                  ml={3}
                  borderRadius={'md'}
                  colorScheme={'red'}
                  fontSize={12}
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'center'}
                  px={2}
                  fontWeight={500}
                >
                  {t('block_final_to_date')}{' '}
                  {dayjs(progetto?.data_blocco_finale).format('DD/MM/YYYY')}
                </Badge>
              )}
            </Box>
          }
          blockRight={
            <Box display={'flex'} alignItems={'center'}>
              <Button
                size={'sm'}
                me={4}
                onClick={async () => {
                  if (!progetto?.preferito) {
                    await createPreferito
                      .mutateAsync({
                        progetto: Number(id!),
                      })
                      .then(() => {
                        refetch()
                      })
                  } else {
                    if (progetto?.preferito_pk) {
                      await deletePreferito
                        .mutateAsync(progetto.preferito_pk)
                        .then(() => {
                          refetch()
                        })
                    }
                  }
                }}
                border={'1px solid'}
                borderColor={'brandBlue'}
                color={'brandBlue'}
                bg={'card'}
                _hover={{
                  bg: 'brandBlue',
                  color: 'white',
                }}
                leftIcon={<MdBookmark />}
              >
                {progetto?.preferito ? t('removeFavorite') : t('addFavorite')}
              </Button>
              {userCanCreateProgetti && (
                <>
                  <Button
                    size={'sm'}
                    color={'white'}
                    onClick={() => {
                      navigate(
                        createStringLink({
                          path: `/projects/edit/${progetto?.id}`,
                          lang: i18n.language,
                        })
                      )
                    }}
                    type={'submit'}
                    _hover={{ bg: 'brand' }}
                    bg={'brandSecondary'}
                  >
                    {t('edit')}
                  </Button>
                </>
              )}
            </Box>
          }
        />
      </Flex>
      <Card width={'100%'} bg={'card'} mb={2}>
        <CardBody py={2}>
          <Grid templateColumns="repeat(11, 1fr)" gap={2} width={'100%'}>
            <GridItem colSpan={1}>
              <Button
                size={'sm'}
                border={'1px solid'}
                borderColor={'brandBlue'}
                color={'brandBlue'}
                bg={'card'}
                _hover={{
                  bg: 'brandBlue',
                  color: 'white',
                }}
                width={'100%'}
                onClick={() => {
                  navigate(`/${i18n.language}/projects/${id}/movements`)
                }}
              >
                {t('movements')}
              </Button>
            </GridItem>
            <GridItem colSpan={1}>
              <Button
                size={'sm'}
                border={'1px solid'}
                borderColor={'brandBlue'}
                color={'brandBlue'}
                bg={'card'}
                _hover={{
                  bg: 'brandBlue',
                  color: 'white',
                }}
                width={'100%'}
                onClick={() => {
                  navigate(`/${i18n.language}/projects/${id}/budgets`)
                }}
              >
                {t('budgets')}
              </Button>
            </GridItem>
            <GridItem colSpan={1}>
              <Button
                size={'sm'}
                border={'1px solid'}
                borderColor={'brandBlue'}
                color={'brandBlue'}
                bg={'card'}
                _hover={{
                  bg: 'brandBlue',
                  color: 'white',
                }}
                width={'100%'}
                onClick={() => {
                  navigate(`/${i18n.language}/projects/${id}/balance`)
                }}
              >
                {t('budget_balance')}
              </Button>
            </GridItem>

            <GridItem colSpan={1}>
              <Button
                size={'sm'}
                border={'1px solid'}
                borderColor={'brandBlue'}
                color={'brandBlue'}
                bg={'card'}
                _hover={{
                  bg: 'brandBlue',
                  color: 'white',
                }}
                width={'100%'}
                onClick={() => {
                  navigate(`/${i18n.language}/projects/${id}/accounts`)
                }}
              >
                {t('accounts')}
              </Button>
            </GridItem>
            <GridItem colSpan={1}>
              <Button
                size={'sm'}
                border={'1px solid'}
                borderColor={'brandBlue'}
                color={'brandBlue'}
                bg={'card'}
                _hover={{
                  bg: 'brandBlue',
                  color: 'white',
                }}
                width={'100%'}
                onClick={() => {
                  navigate(`/${i18n.language}/projects/${id}/contacts`)
                }}
              >
                {t('contacts')}
              </Button>
            </GridItem>
            <GridItem colSpan={1}>
              <Button
                size={'sm'}
                border={'1px solid'}
                borderColor={'brandBlue'}
                color={'brandBlue'}
                bg={'card'}
                _hover={{
                  bg: 'brandBlue',
                  color: 'white',
                }}
                width={'100%'}
                onClick={() => {
                  navigate(`/${i18n.language}/projects/${id}/donors`)
                }}
              >
                {t('donors')}
              </Button>
            </GridItem>
            <GridItem colSpan={1}>
              <Button
                size={'sm'}
                border={'1px solid'}
                borderColor={'brandBlue'}
                color={'brandBlue'}
                bg={'card'}
                _hover={{
                  bg: 'brandBlue',
                  color: 'white',
                }}
                width={'100%'}
                onClick={() => {
                  navigate(`/${i18n.language}/projects/${id}/collaborations`)
                }}
              >
                {t('collaborations')}
              </Button>
            </GridItem>
            <GridItem colSpan={1}>
              <Button
                size={'sm'}
                border={'1px solid'}
                borderColor={'brandBlue'}
                color={'brandBlue'}
                bg={'card'}
                _hover={{
                  bg: 'brandBlue',
                  color: 'white',
                }}
                width={'100%'}
                onClick={() => {
                  navigate(`/${i18n.language}/projects/${id}/suppliers`)
                }}
              >
                {t('suppliers')}
              </Button>
            </GridItem>
            <GridItem colSpan={1}>
              <Button
                size={'sm'}
                border={'1px solid'}
                borderColor={'brandBlue'}
                color={'brandBlue'}
                bg={'card'}
                _hover={{
                  bg: 'brandBlue',
                  color: 'white',
                }}
                width={'100%'}
                onClick={() => {
                  navigate(`/${i18n.language}/projects/${id}/goods`)
                }}
              >
                {t('goods')}
              </Button>
            </GridItem>
            <GridItem colSpan={1}>
              <Button
                size={'sm'}
                border={'1px solid'}
                borderColor={'brandBlue'}
                color={'brandBlue'}
                bg={'card'}
                _hover={{
                  bg: 'brandBlue',
                  color: 'white',
                }}
                width={'100%'}
                onClick={() => {
                  navigate(`/${i18n.language}/projects/${id}/financing`)
                }}
              >
                {t('financing')}
              </Button>
            </GridItem>
            <GridItem colSpan={1}>
              <Button
                size={'sm'}
                border={'1px solid'}
                borderColor={'brandBlue'}
                color={'brandBlue'}
                bg={'card'}
                _hover={{
                  bg: 'brandBlue',
                  color: 'white',
                }}
                width={'100%'}
                onClick={() => {
                  navigate(`/${i18n.language}/projects/${id}/permissions`)
                }}
              >
                {t('permissions')}
              </Button>
            </GridItem>
          </Grid>
        </CardBody>
      </Card>
      <Flex
        flexDirection={'column'}
        width={'100%'}
        height={'calc(100vh - 210px)'}
        overflowY={'auto'}
      >
        <Card width={'100%'}>
          <CardHeader py={2} bg={'brandLight'} fontWeight={600} fontSize={14}>
            {t('info_project')}
          </CardHeader>
          <CardBody>
            <Box
              display={'flex'}
              alignItems={'center'}
              borderBottom={'1px solid #E2E8F0'}
            >
              <Box
                display={'flex'}
                justifyContent={'space-between'}
                width={'100%'}
              >
                <Box>
                  <Text fontSize={15} fontWeight={600} color={'darkblue'}>
                    {progetto?.codice}
                  </Text>
                </Box>
                {progetto?.closed ? (
                  <Box display={'flex'} alignItems={'center'}>
                    <Box
                      display={'inline-block'}
                      width={2}
                      height={2}
                      borderRadius={'50%'}
                      bg={'error'}
                      mr={2}
                    />
                    <Text
                      fontSize={12}
                      fontWeight={400}
                      textTransform={'capitalize'}
                      color={'darkblue'}
                    >
                      {t('closed')}{' '}
                    </Text>
                  </Box>
                ) : (
                  <Box display={'flex'} alignItems={'center'}>
                    <Box
                      display={'inline-block'}
                      width={2}
                      height={2}
                      borderRadius={'50%'}
                      bg={'success'}
                      mr={2}
                    />
                    <Text
                      fontSize={12}
                      fontWeight={400}
                      textTransform={'capitalize'}
                      color={'darkblue'}
                    >
                      {t('open')}{' '}
                    </Text>
                  </Box>
                )}
              </Box>
            </Box>
            <Box mt={2} pb={2} borderBottom={'1px solid #E2E8F0'}>
              <Text fontSize={14} fontWeight={500} color={'darkblue'}>
                {progetto?.titolo}
              </Text>
            </Box>
          </CardBody>
        </Card>
        <Grid templateColumns="repeat(2, 1fr)" gap={3} width={'100%'}>
          <GridItem colSpan={1}>
            <Card width={'100%'} mt={2}>
              <CardHeader
                py={2}
                bg={'brandLight'}
                fontWeight={600}
                fontSize={14}
              >
                {t('data_project')}
              </CardHeader>
              <CardBody>
                <Box display={'flex'} alignItems={'center'}>
                  <MdCalendarToday size={20} color={'orange'} />
                  <Box ml={2} display={'flex'} fontSize={14} fontWeight={500}>
                    <Text fontWeight={300} ms={1} minWidth={100}>
                      {t('period')}
                    </Text>
                    <Text ms={3} color={'darkblue'}>
                      {dayjs(progetto?.data_inizio).format('DD/MM/YYYY')}
                      {' - '}
                      {dayjs(progetto?.data_fine).format('DD/MM/YYYY')}
                    </Text>
                  </Box>
                </Box>
                <Box display={'flex'} alignItems={'center'} mt={2}>
                  <MdLocationOn size={20} color={'orange'} />
                  <Box ml={2} display={'flex'} fontSize={14} fontWeight={500}>
                    <Text fontWeight={300} ms={1} minWidth={100}>
                      {t('country')}
                    </Text>
                    <Text ms={3} color={'darkblue'}>
                      {progetto?.paesi_intervento_data
                        ?.map((paese) => {
                          return paese.nome
                        })
                        .join(', ')}
                    </Text>
                  </Box>
                </Box>
                <Box display={'flex'} alignItems={'center'} mt={2}>
                  <BsCurrencyExchange size={20} color={'orange'} />
                  <Box ml={2} display={'flex'} fontSize={14} fontWeight={500}>
                    <Text fontWeight={300} ms={1} minWidth={100}>
                      {t('exchange_type')}
                    </Text>
                    <Text ms={5} color={'darkblue'}>
                      {progetto?.tipo_cambio_data?.codice}
                    </Text>
                  </Box>
                </Box>
                <Box display={'flex'} alignItems={'center'} mt={2}>
                  <FaMoneyBillWave size={20} color={'orange'} />
                  <Box ml={2} display={'flex'} fontSize={14} fontWeight={500}>
                    <Text fontWeight={300} ms={1} minWidth={100}>
                      {t('main_local_currency')}
                    </Text>
                    <Badge
                      ms={3}
                      px={2}
                      borderRadius={'md'}
                      display={'flex'}
                      alignItems={'center'}
                    >
                      {progetto?.valuta_locale_principale_data?.codice}
                    </Badge>
                  </Box>
                </Box>
                <Box display={'flex'} alignItems={'center'} mt={2}>
                  <FaMoneyBillWave size={20} color={'orange'} />
                  <Box ml={2} display={'flex'} fontSize={14} fontWeight={500}>
                    <Text fontWeight={300} ms={1} minWidth={100}>
                      {t('reporting_currency')}
                    </Text>
                    <Badge
                      ms={3}
                      px={2}
                      borderRadius={'md'}
                      display={'flex'}
                      alignItems={'center'}
                    >
                      {progetto?.valuta_rendicontazione_data?.codice}
                    </Badge>
                  </Box>
                </Box>
                <Box display={'flex'} alignItems={'center'} mt={2} pb={1}>
                  <FaMoneyBillWave size={20} color={'orange'} />
                  <Box
                    ml={2}
                    display={'flex'}
                    fontSize={14}
                    alignItems={'center'}
                    fontWeight={500}
                  >
                    <Text fontWeight={300} ms={1} minWidth={100}>
                      {t('local_currencies')}
                    </Text>
                    <Text color={'darkblue'} display={'flex'}>
                      {progetto?.valute_locali_data?.map((valuta, i) => {
                        return (
                          <Badge
                            key={i}
                            px={2}
                            borderRadius={'md'}
                            display={'flex'}
                            alignItems={'center'}
                            ms={3}
                          >
                            {valuta.codice}
                          </Badge>
                        )
                      })}
                    </Text>
                  </Box>
                </Box>
                <Box display={'flex'} alignItems={'center'} mt={2} pb={8}>
                  <MdMonetizationOn size={20} color={'orange'} />
                  <Box ml={2} display={'flex'} fontSize={14} fontWeight={500}>
                    <Text fontWeight={300} ms={1} minWidth={100}>
                      {t('total_import')}
                    </Text>
                    <Text ms={3} fontWeight={700} color={'darkblue'}>
                      {numberFormatter.format(progetto?.importo_totale ?? 0)}
                    </Text>
                  </Box>
                </Box>
              </CardBody>
            </Card>

            <Card width={'100%'} mt={3}>
              <CardHeader
                py={2}
                bg={'brandLight'}
                fontWeight={600}
                fontSize={14}
              >
                {t('block_project')}
              </CardHeader>
              <CardBody>
                <Box
                  display={'flex'}
                  pb={2}
                  alignItems={'center'}
                  borderBottom={'1px solid #E2E8F0'}
                >
                  <Box>
                    <Text fontWeight={300} fontSize={13} minWidth={130}>
                      {t('block_general')}
                    </Text>
                    <Text color={'darkblue'} fontSize={13} fontWeight={500}>
                      {progetto?.inserimento_bloccato
                        ? t('block_insertion')
                        : t('project_not_blocked')}
                    </Text>
                  </Box>
                  <Box ml={'auto'}>
                    <Button
                      size={'xs'}
                      border={'1px solid'}
                      borderColor={'brand'}
                      bg={'white'}
                      _hover={{ bg: 'brandLight' }}
                      color={'brand'}
                      fontWeight={500}
                      onClick={() => {
                        onOpenBloccoProgetto()
                      }}
                    >
                      {t('manage_project_block')}
                    </Button>
                  </Box>
                </Box>
                <Box
                  display={'flex'}
                  pb={2}
                  pt={2}
                  alignItems={'center'}
                  borderBottom={'1px solid #E2E8F0'}
                >
                  <Box>
                    <Text fontWeight={300} fontSize={13} minWidth={130}>
                      {t('block_on_competence_date')}
                    </Text>
                    <Text color={'darkblue'} fontSize={13} fontWeight={500}>
                      {progetto?.data_blocco_compentenza
                        ? dayjs(progetto?.data_blocco_compentenza).format(
                            'DD/MM/YYYY'
                          )
                        : t('no_block_on_competence_date')}
                    </Text>
                  </Box>
                  <Box ml={'auto'}>
                    <Button
                      size={'xs'}
                      border={'1px solid'}
                      borderColor={'brand'}
                      bg={'white'}
                      _hover={{ bg: 'brandLight' }}
                      color={'brand'}
                      fontWeight={500}
                      onClick={() => {
                        onOpenBloccoData()
                      }}
                    >
                      {t('manage_block_on_competence_date')}
                    </Button>
                  </Box>
                </Box>
                <Box
                  display={'flex'}
                  pb={2}
                  pt={2}
                  alignItems={'center'}
                  borderBottom={'1px solid #E2E8F0'}
                >
                  <Box maxWidth={'65% '}>
                    <Text fontWeight={300} fontSize={13} minWidth={130}>
                      {t('block_final_on_competence_date')}
                    </Text>
                    <Text color={'darkblue'} fontSize={13} fontWeight={500}>
                      {progetto?.data_blocco_finale
                        ? dayjs(progetto?.data_blocco_finale).format(
                            'DD/MM/YYYY'
                          )
                        : t('no_block_final_on_competence_date')}
                    </Text>
                  </Box>
                  <Box ml={'auto'}>
                    <Button
                      size={'xs'}
                      border={'1px solid'}
                      borderColor={'brand'}
                      bg={'white'}
                      _hover={{ bg: 'brandLight' }}
                      color={'brand'}
                      fontWeight={500}
                      onClick={() => {
                        onOpenBloccoFinale()
                      }}
                    >
                      {t('manage_block_final_on_competence_date')}
                    </Button>
                  </Box>
                </Box>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem colSpan={1}>
            <Card width={'100%'} mt={2}>
              <CardHeader
                py={2}
                bg={'brandLight'}
                fontWeight={600}
                fontSize={14}
              >
                {t('additional_info')}
              </CardHeader>
              <CardBody minHeight={240}>
                <Box display={'flex'} alignItems={'center'}>
                  <Text fontWeight={300} fontSize={14} ms={1} minWidth={130}>
                    {t('contact')}
                  </Text>
                  <Text
                    ms={3}
                    color={'darkblue'}
                    fontSize={14}
                    fontWeight={500}
                  >
                    {progetto?.referente_data?.referente_generico_data?.nome}{' '}
                  </Text>
                </Box>
                <Box display={'flex'} alignItems={'center'} mt={2}>
                  <Text fontWeight={300} fontSize={14} ms={1} minWidth={130}>
                    {t('code_donor')}
                  </Text>
                  <Text
                    ms={3}
                    color={'darkblue'}
                    fontSize={14}
                    fontWeight={500}
                  >
                    {progetto?.codice_progetto_donatore}
                  </Text>
                </Box>
                {progetto?.ente_principale_data && (
                  <Box display={'flex'} mt={2}>
                    <Text fontWeight={300} fontSize={14} ms={1} minWidth={130}>
                      {t('main_entity')}
                    </Text>
                    <Text
                      ms={3}
                      color={'darkblue'}
                      fontSize={14}
                      fontWeight={500}
                    >
                      {progetto?.ente_principale_data?.nome}
                    </Text>
                  </Box>
                )}
                <Box display={'flex'} mt={2}>
                  <Text fontWeight={300} fontSize={14} ms={1} minWidth={130}>
                    {t('intervention_area')}
                  </Text>
                  <Text
                    ms={3}
                    color={'darkblue'}
                    fontSize={14}
                    fontWeight={500}
                  >
                    {progetto?.area_intervento_data?.nome}
                  </Text>
                </Box>
                <Box display={'flex'} mt={2}>
                  <Text fontWeight={300} fontSize={14} ms={1} minWidth={130}>
                    {t('intervention_sectors')}
                  </Text>
                  <Text
                    ms={3}
                    color={'darkblue'}
                    fontSize={14}
                    fontWeight={500}
                  >
                    {progetto?.settori_intervento_data?.map((settore, i) => {
                      return <Text key={i}>{settore.nome}</Text>
                    })}
                  </Text>
                </Box>
                <Box display={'flex'} pb={2} mt={2}>
                  <Text fontWeight={300} ms={1} fontSize={14} minWidth={130}>
                    {t('number_of_reports')}
                  </Text>
                  <Text
                    ms={3}
                    color={'darkblue'}
                    fontSize={13}
                    fontWeight={500}
                  >
                    {progetto?.periodi_rendicontazione}
                  </Text>
                </Box>
                <Box display={'flex'} pb={2}>
                  <Text fontWeight={300} ms={1} fontSize={14} minWidth={130}>
                    {t('exchange_type')}
                  </Text>
                  <Text
                    ms={3}
                    color={'darkblue'}
                    fontSize={13}
                    fontWeight={500}
                  >
                    {progetto?.tipo_cambio_data?.codice} -{' '}
                    {progetto?.tipo_cambio_data?.descrizione}
                  </Text>
                </Box>
                <Box display={'flex'} pb={2}>
                  <Text fontWeight={300} ms={1} fontSize={14} minWidth={130}>
                    {t('exchange_type_euro')}
                  </Text>
                  <Text
                    ms={3}
                    color={'darkblue'}
                    fontSize={13}
                    fontWeight={500}
                  >
                    {progetto?.tipo_cambio_data_euro?.codice} -{' '}
                    {progetto?.tipo_cambio_data_euro?.descrizione}
                  </Text>
                </Box>
              </CardBody>
            </Card>
            <Card width={'100%'} mt={3}>
              <CardHeader
                py={2}
                bg={'brandLight'}
                fontWeight={600}
                fontSize={14}
              >
                {t('info_actions_on_project')}
              </CardHeader>
              <CardBody fontSize={14} minHeight={200}>
                <Box display={'flex'} alignItems={'center'}>
                  <Text fontWeight={300} fontSize={14} ms={1} minWidth={130}>
                    {t('created_at')}:
                  </Text>
                  <Text
                    ms={3}
                    color={'darkblue'}
                    fontSize={14}
                    fontWeight={500}
                  >
                    {createdAt}
                  </Text>
                </Box>
                <Box display={'flex'} alignItems={'center'} mt={2}>
                  <Text fontWeight={300} fontSize={14} ms={1} minWidth={130}>
                    {t('last_update')}:
                  </Text>
                  <Text
                    ms={3}
                    color={'darkblue'}
                    fontSize={14}
                    fontWeight={500}
                  >
                    {updatedAt}
                  </Text>
                </Box>
                <Box display={'flex'} alignItems={'center'} mt={2}>
                  <Text fontWeight={300} fontSize={14} ms={1} minWidth={130}>
                    {t('last_update_by')}:
                  </Text>
                  <Text
                    ms={3}
                    color={'darkblue'}
                    fontSize={14}
                    fontWeight={500}
                  >
                    {updateBy}
                  </Text>
                </Box>
                {user.is_staff && (
                  <Box mt={4} width={'100%'}>
                    <Box
                      display={'flex'}
                      justifyContent={'flex-start'}
                      fontSize={14}
                      alignItems={'center'}
                      _hover={{
                        cursor: 'pointer',
                        textDecoration: 'underline',
                      }}
                      onClick={() => onOpenLogs()}
                    >
                      <BsClockFill color="orange" />{' '}
                      <Text ms={2}>{t('view_the_project_story')}</Text>
                    </Box>
                  </Box>
                )}
                {(user.is_staff ||
                  user.permessi?.visualizza_movimenti_eliminati) && (
                  <Box mt={2} width={'100%'}>
                    <Box
                      display={'flex'}
                      justifyContent={'flex-start'}
                      fontSize={14}
                      alignItems={'center'}
                      _hover={{
                        cursor: 'pointer',
                        textDecoration: 'underline',
                      }}
                      onClick={() => onOpenLogsMovimentiEliminati()}
                    >
                      <BsClockFill color="orange" />{' '}
                      <Text ms={2}>{t('view_deleted_movements')}</Text>
                    </Box>
                  </Box>
                )}
              </CardBody>
            </Card>
          </GridItem>
          <GridItem colSpan={2}>
            {progetto?.cambi_mancanti && progetto.cambi_mancanti.length > 0 && (
              <Alert
                status="warning"
                py={2}
                border={'0.5px solid'}
                borderColor={'red'}
                flexDirection="row"
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                textAlign="center"
                textColor={'red.400'}
                bg={'white'}
                width={'100%'}
                borderRadius={'md'}
                fontSize={14}
                fontWeight={600}
              >
                <Box display={'flex'}>
                  <MdWarningAmber size={20} />
                  <Text ms={3}>
                    {t('attention_there_are_movements_without_exchanges')}:{' '}
                    {progetto.cambi_mancanti.length}
                  </Text>
                </Box>
                <Button
                  size={'sm'}
                  onClick={() => {
                    navigate(
                      createStringLink({
                        lang: i18n.language,
                        path: `/projects/${id}/movements?valori_cambio_mancanti=true`,
                      })
                    )
                  }}
                >
                  {t('list_movements')}
                </Button>
              </Alert>
            )}
          </GridItem>
          <GridItem colSpan={2}>
            <Card width={'100%'}>
              <CardHeader
                py={2}
                bg={'brandLight'}
                fontWeight={600}
                fontSize={14}
              >
                {t('movements_summary')}
              </CardHeader>
              <CardBody>
                <Table size={'sm'} variant={'pinc'}>
                  <Thead>
                    <Tr>
                      <Th py={1}>{t('date_start')}</Th>
                      <Th py={1}>{t('date_end')}</Th>
                      <Th py={1}>{t('covers')}</Th>
                      <Th py={1}>{t('attachments')}</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {progetto?.riassunto_documenti?.map((doc) => (
                      <Tr key={doc.id}>
                        <Td fontSize={14}>
                          {dayjs(doc.data_inizio).format('DD/MM/YYYY')}
                        </Td>
                        <Td fontSize={14}>
                          {dayjs(doc.data_fine).format('DD/MM/YYYY')}
                        </Td>
                        <Td textAlign={'center'}>
                          {doc.pdf_copertine && (
                            <Link
                              display={'flex'}
                              href={`${doc.pdf_copertine}`}
                              isExternal
                            >
                              <BsFilePdfFill color={'orange'} size={15} />{' '}
                              <Box ms={2}>{'PDF'}</Box>
                            </Link>
                          )}
                        </Td>
                        <Td>
                          {doc.zip_allegati && (
                            <Link
                              href={`${doc.zip_allegati}`}
                              isExternal
                              color={'brand'}
                              fontWeight={500}
                            >
                              <BsFileZip size={20} />
                            </Link>
                          )}
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem colSpan={2}>
            <Card width={'100%'}>
              <CardHeader
                py={2}
                bg={'brandLight'}
                fontWeight={600}
                fontSize={14}
              >
                <Box
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'space-between'}
                >
                  <Box>{t('internal_control')}</Box>
                  {(progetto?.controllo_job === 'started' ||
                    progetto?.controllo_job === 'queued') && (
                    <Box
                      display={'flex'}
                      alignItems={'center'}
                      color={'primary'}
                      fontWeight={700}
                      fontSize={14}
                    >
                      <Spinner size="sm" me={2} />{' '}
                      {t('control_interno_in_fase_di_registrazione')} ...
                    </Box>
                  )}
                </Box>
              </CardHeader>
              <CardBody>
                <Table size={'sm'} maxWidth={'100%'} variant={'pinc'}>
                  <Thead>
                    <Tr>
                      <Th py={1}>{t('control_file')}</Th>
                      <Th py={1}>{t('date')}</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {progetto?.controlli_interni?.map((controllo) => (
                      <Tr key={controllo.id}>
                        <Td fontSize={14} textAlign={'center'}>
                          {controllo.excel_file && (
                            <Link
                              display={'flex'}
                              alignItems={'center'}
                              href={`${controllo.excel_file}`}
                              isExternal
                            >
                              <FaFileExcel color={'orange'} size={15} />
                              <Box ms={2}>{'Excel'}</Box>
                            </Link>
                          )}
                        </Td>
                        <Td>
                          {dayjs(controllo.modified).format('DD/MM/YYYY')}
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem colSpan={2}>
            <Card width={'100%'}>
              <CardHeader
                py={2}
                bg={'brandLight'}
                fontWeight={600}
                fontSize={14}
              >
                {t('notes')}
              </CardHeader>
              <CardBody>
                {progetto?.note ? (
                  <Text fontSize={14} fontWeight={500} color={'darkblue'}>
                    {progetto?.note}
                  </Text>
                ) : (
                  <Text fontSize={14} fontWeight={500} color={'darkblue'}>
                    {t('no_notes')}
                  </Text>
                )}
              </CardBody>
            </Card>
          </GridItem>
          <GridItem colSpan={2}>
            <Card width={'100%'}>
              <CardHeader
                py={2}
                bg={'brandLight'}
                fontWeight={600}
                fontSize={14}
              >
                {t('attachments')}
              </CardHeader>
              <CardBody>
                {progetto?.allegato ? (
                  <Text
                    display={'flex'}
                    fontSize={14}
                    fontWeight={500}
                    color={'darkblue'}
                  >
                    <MdAttachment size={20} color={'orange'} />
                    <Link
                      isExternal
                      href={progetto?.allegato}
                      target={'_blank'}
                      rel="noopener noreferrer"
                      ml={2}
                    >
                      {t('attachment')}
                    </Link>
                  </Text>
                ) : (
                  <Text fontSize={14} fontWeight={500} color={'darkblue'}>
                    {t('no_attachments')}
                  </Text>
                )}
                {progetto?.allegato_2 && (
                  <Text
                    display={'flex'}
                    fontSize={14}
                    fontWeight={500}
                    color={'darkblue'}
                  >
                    <MdAttachment size={20} color={'orange'} />
                    <Link
                      isExternal
                      href={progetto.allegato_2}
                      target={'_blank'}
                      rel="noopener noreferrer"
                      ml={2}
                    >
                      {t('attachment_2')}
                    </Link>
                  </Text>
                )}
                {progetto?.allegato_3 && (
                  <Text
                    display={'flex'}
                    fontSize={14}
                    fontWeight={500}
                    color={'darkblue'}
                  >
                    <MdAttachment size={20} color={'orange'} />
                    <Link
                      isExternal
                      href={progetto.allegato_3}
                      target={'_blank'}
                      rel="noopener noreferrer"
                      ml={2}
                    >
                      {t('attachment_3')}
                    </Link>
                  </Text>
                )}
                {progetto?.allegato_4 && (
                  <Text
                    display={'flex'}
                    fontSize={14}
                    fontWeight={500}
                    color={'darkblue'}
                  >
                    <MdAttachment size={20} color={'orange'} />
                    <Link
                      isExternal
                      href={progetto.allegato_4}
                      target={'_blank'}
                      rel="noopener noreferrer"
                      ml={2}
                    >
                      {t('attachment_4')}
                    </Link>
                  </Text>
                )}
                {progetto?.allegato_5 && (
                  <Text
                    display={'flex'}
                    fontSize={14}
                    fontWeight={500}
                    color={'darkblue'}
                  >
                    <MdAttachment size={20} color={'orange'} />
                    <Link
                      isExternal
                      href={progetto.allegato_5}
                      target={'_blank'}
                      rel="noopener noreferrer"
                      ml={2}
                    >
                      {t('attachment_5')}
                    </Link>
                  </Text>
                )}
              </CardBody>
            </Card>
          </GridItem>
        </Grid>
      </Flex>
      <ModalBloccoProgetto
        isOpen={isOpenBloccoProgetto}
        onClose={onCloseBloccoProgetto}
        onOpen={onOpenBloccoProgetto}
        onToggle={onToggleBloccoProgetto}
        onConfirm={async (values: any) => {
          const inserimentoBloccato = values.inserimento_bloccato ? true : false
          await updateInserimentoBloccato.mutateAsync(inserimentoBloccato)
          onCloseBloccoProgetto()
        }}
        progetto={progetto}
      />
      <ModalBloccoFinale
        isOpen={isOpenBloccoFinale}
        onClose={onCloseBloccoFinale}
        onOpen={onOpenBloccoFinale}
        onToggle={onToggleBloccoFinale}
        onConfirm={async (values: any) => {
          const finaleBloccato = values.data_blocco_finale ?? undefined
          await updateBloccoFinale.mutateAsync(finaleBloccato)
          onCloseBloccoFinale()
        }}
        progetto={progetto}
      />
      <ModalBloccoData
        isOpen={isOpenBloccoData}
        onClose={onCloseBloccoData}
        onOpen={onOpenBloccoData}
        onToggle={onToggleBloccoData}
        onConfirm={async (values: any) => {
          const finaleBloccato = values.data_blocco_compentenza as string
          const bloccoCompentenzaGestori = values.blocco_compentenza_gestori
            ? true
            : false
          await updateBloccoData.mutateAsync({
            data_blocco_compentenza: finaleBloccato,
            blocco_compentenza_gestori: bloccoCompentenzaGestori,
          })
          onCloseBloccoData()
        }}
        progetto={progetto}
      />
      <ModalLogs
        isOpen={isOpenLogs}
        onClose={onCloseLogs}
        onOpen={onOpenLogs}
        onToggle={onToggleLogs}
        title={t('history_of_project') + ' - ' + progetto?.codice}
        logs={progetto?.logs || []}
      />
      {isOpenLogsMovimentiEliminati && (
        <ModalLogsMovements
          isOpen={isOpenLogsMovimentiEliminati}
          onClose={onCloseLogsMovimentiEliminati}
          onOpen={onOpenLogsMovimentiEliminati}
          onToggle={onToggleLogsMovimentiEliminati}
          title={t('movements_deleted') + ' - ' + progetto?.codice}
          progetto={progetto}
        />
      )}
    </Box>
  )
}
