import {
  Alert,
  Badge,
  Box,
  Button,
  Checkbox,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import {
  useMovimentiCancellati,
  useMovimentiDownloadCancellati,
  useRipristinaMovimenti,
} from '../hooks/movimenti'
import { OPTIONS_CAUSALI } from '../consts'
import { numberFormatter } from '../utils'
import { useMemo, useState } from 'react'
import Paginator from './Paginator'
import { useQsFilters } from '../hooks/filters'
import ModalConfirm from './ModalConfirm'
import { Progetto } from '../types'
import DownloadButton from './DownloadButton'

interface ModalLogsProps {
  title: string
  isOpen: boolean
  onClose: () => void
  onOpen: () => void
  onToggle: () => void
  progetto: Progetto | undefined
}

const initFilters = (params: URLSearchParams) => ({
  page: Number(params.get('page') ?? 1),
})

export default function ModalLogsMovements({
  title,
  isOpen,
  onClose,
  progetto,
}: ModalLogsProps) {
  const { t } = useTranslation()

  const [movimentiForCheck, setMovimentiForCheck] = useState<number[]>([])
  const { uiFilters, setFilters, filters } = useQsFilters(initFilters)
  const filtersWithProgetto = useMemo(() => {
    return {
      ...filters,
      progetto: progetto?.id,
    }
  }, [filters])
  const logs = useMovimentiCancellati(filtersWithProgetto)
  const ripristinaMovimenti = useRipristinaMovimenti()
  const {
    isOpen: isOpenConfirm,
    onOpen,
    onClose: onCloseConfirm,
  } = useDisclosure()
  const filtersWithProgettoEliminato = useMemo(() => {
    return {
      ...filters,
      progetto: progetto?.id,
    }
  }, [filters])
  const downloadWithParams = useMovimentiDownloadCancellati(
    filtersWithProgettoEliminato
  )

  const toast = useToast()
  
  return (
    <>
      <Modal isCentered size={'6xl'} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{title}</ModalHeader>
          <ModalCloseButton />
          <ModalBody pt={-5} maxHeight={400} overflowY={'auto'} bg={'white'}>
            {movimentiForCheck.length > 0 && (
              <Alert status={'info'} mb={4}>
                <Flex
                  justifyContent={'space-between'}
                  width={'100%'}
                  alignItems={'center'}
                >
                  <Box fontWeight={700}>
                    {movimentiForCheck.length} {t('movements_selected')}
                  </Box>
                  <Button
                    size={'sm'}
                    color={'white'}
                    _hover={{ bg: 'brand' }}
                    bg={'brandSecondary'}
                    onClick={() => {
                      onOpen()
                    }}
                  >
                    {t('restore_movements')}
                  </Button>
                </Flex>
              </Alert>
            )}
            <Table variant={'pinc'}>
              <Thead position={'sticky'} top={0}>
                <Tr>
                  <Th>
                    <Checkbox
                      width={5}
                      height={5}
                      size={'md'}
                      colorScheme={'orange'}
                      borderColor={'brandLight'}
                      isChecked={
                        movimentiForCheck.length === logs.data?.results.length
                      }
                      onChange={(e) => {
                        if (e.target.checked) {
                          setMovimentiForCheck(
                            logs?.data?.results.map((m) => m.id) ?? []
                          )
                        } else {
                          setMovimentiForCheck([])
                        }
                      }}
                    />
                  </Th>
                  <Th>{t('progressive')}</Th>
                  <Th>{t('email')}</Th>
                  <Th>{t('date')}</Th>
                  <Th>{t('causale')}</Th>
                  <Th>{t('description')}</Th>
                  <Th>{t('amount')}</Th>
                  <Th>{t('amount_rendicontation')}</Th>
                </Tr>
              </Thead>
              <Tbody>
                {logs.data?.results?.map((log) => (
                  <Tr key={log.id}>
                    <Td>
                      <Checkbox
                        width={5}
                        height={5}
                        size={'md'}
                        colorScheme={'orange'}
                        borderColor={'brandLight'}
                        isChecked={movimentiForCheck.includes(log.id)}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setMovimentiForCheck([...movimentiForCheck, log.id])
                          } else {
                            setMovimentiForCheck(
                              movimentiForCheck.filter((m) => m !== log.id)
                            )
                          }
                        }}
                      />
                    </Td>
                    <Td>{log.progressivo}</Td>
                    <Td>{log?.utente_eliminazione}</Td>
                    <Td>{dayjs(log.data_eliminazione).format('DD/MM/YYYY')}</Td>
                    <Td>
                      {t(
                        OPTIONS_CAUSALI.find(
                          (causale) => causale.value === log.causale
                        )?.label ?? ''
                      )}
                      {log.causale === 'anticipo-cassa' && (
                        <Box mt={1}>
                          {log.anticipo_chiuso ? (
                            <Badge colorScheme={'green'}>{t('Closed')}</Badge>
                          ) : (
                            <Badge colorScheme={'red'}>{t('Open')}</Badge>
                          )}
                        </Box>
                      )}
                    </Td>
                    <Td>{log.descrizione}</Td>
                    <Td textAlign={'right'}>
                      <Box color={'brand'} fontWeight={700}>
                        {numberFormatter.format(log.importo ?? 0)}
                      </Box>
                      {log?.valuta_data?.codice}
                    </Td>
                    <Td textAlign={'right'}>
                      {log.importo_rendicontazione && (
                        <>
                          <Box color={'brand'} fontWeight={700}>
                            {numberFormatter.format(
                              log.importo_rendicontazione ?? 0
                            )}
                          </Box>
                          {
                            log.progetto_data?.valuta_rendicontazione_data
                              ?.codice
                          }
                        </>
                      )}
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
            <Flex justifyContent={'center'} mt={4}>
              <Paginator
                count={logs?.data?.count ?? 0}
                currentPage={uiFilters.page}
                goToPage={(page) => setFilters({ page })}
              />
            </Flex>
          </ModalBody>
          <ModalFooter display={'flex'} justifyContent={'space-between'}>
            <DownloadButton
              onClick={() => downloadWithParams()}
              isDisabled={logs.isLoading}
            />
            <Button
              size={'sm'}
              outline={'solid'}
              _hover={{
                bg: 'brand',
                color: 'white',
              }}
              onClick={onClose}
              bg={'brandSecondary'}
              color={'white'}
            >
              {t('close')}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <ModalConfirm
        title={t('restore_movements')}
        text={t('restore_movements_description') ?? ''}
        onToggle={onCloseConfirm}
        isOpen={isOpenConfirm}
        onOpen={onOpen}
        onClose={onCloseConfirm}
        onConfirm={() => {
          ripristinaMovimenti.mutateAsync(movimentiForCheck).then(() => {
            const movimentiLength = movimentiForCheck.length
            onCloseConfirm()
            setMovimentiForCheck([])
            toast({
              title: t('restore_movements'),
              description: t('movements_restored_successfully'),
              status: 'success',
              duration: 10000,
              isClosable: true,
            })
          })
        }}
      />
    </>
  )
}
