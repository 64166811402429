import * as Yup from 'yup'
import { FieldArray, Formik, useFormikContext } from 'formik'
import {
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  Divider,
  Flex,
  Grid,
  GridItem,
  Switch,
  useDisclosure,
} from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'

import { useEffect, useMemo, useState } from 'react'
import { AccountUser, Movimento, Progetto } from '../../../types'
import { useParams } from 'react-router'
import HeaderActionsPage from '../../../components/HeaderActionsPage'
import {
  DateField,
  InputField,
  InputFileField,
  ReactSelectField,
  SelectChakraField,
  TextareaField,
  transformErrorForForm,
} from '../../../components/form'
import BreadCrumb from '../../../components/BreadCrumb'
import {
  useCreateCollaborazioneProgetto,
  useCreateDonatoreProgetto,
  useCreateFornitoreProgetto,
  useProgettoSimple,
} from '../../../hooks/progetti'
import {
  TIPO_PROCEDURA_ACQUISTO_OPTIONS,
  OPTIONS_CAUSALI,
  getCausaliOptions,
} from '../../../consts'
import { orderBy, set } from 'lodash'
import dayjs from 'dayjs'
import AutoCompleteField from '../../../components/form/fields'
import { useModalitaPagamento } from '../../../hooks/modalita-pagamento'
import ModalFormFornitore from '../../Fornitori/ModalFormFornitore'
import ModalFormDonatore from '../../Donatori/ModalFormDonatore'
import ModalFormBene from '../../Beni/ModalFormBene'
import { useCreateBene } from '../../../hooks/beni'
import { useCausaleContextState } from '../../../context/CausaleContext'
import {
  useCanGestioneAnagrafiche,
  useCanGestioneBeni,
  useCanGestioneFornitori,
} from '../../../permissions'
import ModalFormCollaborazione from '../Collaborazioni/ModalFormCollaborazione'
import { numberFormatter } from '../../../utils'
import { useValoriCambio } from '../../../hooks/valori-cambio'
import { BiTrash } from 'react-icons/bi'
import { useMovimento } from '../../../hooks/movimenti'
import { useQsFilters } from '../../../hooks/filters'
import ModalSearchFornitore from '../../Fornitori/ModalSearchFornitore'
import { useAssociaProgettoFornitore } from '../../../hooks/fornitori'
import { useAuthUser } from 'use-eazy-auth'
import ModalFormCollaboratore from '../../Collaboratori/ModalFormCollaboratore'
import { useCreateCollaboratore } from '../../../hooks/collaboratori'

interface MovimentoFormProps {
  movimento?: Movimento
  movimentoFromCopia?: number
  progettiCopia?: number
  save: (movimento: Movimento) => Promise<void>
}

export function MovimentoGridForm({
  onOpenBene,
  onOpenDonatore,
  onOpenFornitore,
  onOpenCollaborazione,
  onOpenSearchFornitore,
  progetto,
  movimento,
  movimentoFromCopia,
  progettiCopia,
  movimentoCopia,
}: {
  onOpenBene: () => void
  onOpenDonatore: () => void
  onOpenFornitore: () => void
  onOpenCollaborazione: () => void
  onOpenSearchFornitore: () => void
  progetto: Progetto | undefined
  movimento: Movimento | undefined
  movimentoFromCopia: number | undefined
  progettiCopia: number | undefined
  movimentoCopia: Movimento | undefined
}) {
  const { t } = useTranslation()
  const { id } = useParams<{ id: string }>()
  const { setFieldValue, values, setErrors, setFieldTouched } =
    useFormikContext<Movimento>()
  const { causali } = useCausaleContextState()

  const { data: modalitaPagamento } = useModalitaPagamento()

  const optionsModalitaPagamento = useMemo(() => {
    const modalitaPagamentoData = modalitaPagamento?.results || []
    const modalitaPagamentoOptions = modalitaPagamentoData.map((v) => ({
      value: v.id,
      label: v.descrizione,
    }))
    if (
      values.causale === 'anticipo-cassa' ||
      values.causale === 'reso-anticipo-cassa'
    ) {
      return modalitaPagamentoOptions.filter(
        (v) => v.label.indexOf('CASSA') > -1
      )
    }
    return modalitaPagamentoOptions
  }, [modalitaPagamento?.results, values.causale])

  const user = useAuthUser()

  const optionsCausali = useMemo(() => {
    let constants = getCausaliOptions(user.user, false)
    if (movimento) {
      constants = getCausaliOptions(user.user, true)
    }
    const options = constants.map((v) => ({
      value: v.value,
      label: t(v.label),
    }))
    return orderBy(options, ['label'], ['asc'])
  }, [movimento, t, user])

  const optionsValute = useMemo(() => {
    const valuteLocali = progetto?.valute_locali_data || []
    const valute = valuteLocali.map((v) => ({
      value: v.id,
      label: v.codice,
    }))
    return valute
  }, [progetto])

  const numeroAnnualita = useMemo(() => {
    if (!progetto) return 1
    return progetto.periodi_rendicontazione ?? 1
  }, [progetto])

  const userCanAddFornitori = useCanGestioneFornitori()
  const userCanGestioneAnagrafiche = useCanGestioneAnagrafiche()
  const userCanGestioneBeni = useCanGestioneBeni()

  useEffect(() => {
    setFieldTouched('importo', true)
    setErrors({
      importo: 'Importo non valido',
    })
  }, [setErrors, setFieldTouched])

  useEffect(() => {
    if (values.importo && values.aliquota_iva) {
      const importoIva = (values.importo * values.aliquota_iva) / 100
      setFieldValue('importo_iva', importoIva)
    }
  }, [values.importo, values.aliquota_iva, setFieldValue])

  useEffect(() => {
    if (values.progetti_copia && values.progetti_copia.length > 0) {
      values.progetti_copia.map((progetto, i) => {
        if (progetto.importo && progetto.aliquota_iva) {
          const importoIva = (progetto.importo * progetto.aliquota_iva) / 100
          setFieldValue(`progetti_copia.${i}.importo_iva`, importoIva)
        } else {
          setFieldValue(`progetti_copia.${i}.aliquota_iva`, 0)
          setFieldValue(`progetti_copia.${i}.importo_iva`, 0)
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { data: cambi } = useValoriCambio({
    valuta_da: values.valuta,
    valuta_a: progetto?.valuta_locale_principale,
    data: values.data_competenza,
  })

  const { data: cambiInverse } = useValoriCambio({
    valuta_da: progetto?.valuta_locale_principale,
    valuta_a: values.valuta,
    data: values.data_competenza,
  })

  const isRequiredModalitaPagamento = useMemo(() => {
    if (values.causale === 'spesa-loco' && values.movimento_anticipo) {
      return false
    } else {
      return (
        values.causale_oggetto &&
        values.causale_oggetto.required.includes('modalita_pagamento')
      )
    }
  }, [values.causale, values.causale_oggetto, values.movimento_anticipo])

  const cambioToUse = useMemo(() => {
    if (!progetto) return 1
    const valutaLocale = values.valuta
    const valutaRendicontazione = progetto.valuta_rendicontazione
    if (valutaLocale === valutaRendicontazione) return 1
    if (cambi && cambi.results.length > 0) {
      return cambi.results[0].valore
    } else if (cambiInverse && cambiInverse.results.length > 0) {
      const cambio = cambiInverse.results[0].valore
      if (!cambio) return 1
      return 1 / cambio
    } else {
      return 0
    }
  }, [cambi, cambiInverse, progetto, values.valuta])

  useEffect(() => {
    if (values.importo && cambioToUse) {
      const importoRendicontazione = values.importo * cambioToUse
      setFieldValue(
        'importo_rendicontazione',
        Number(importoRendicontazione.toFixed(2))
      )
    }
  }, [values.importo, cambioToUse, setFieldValue])

  const [isAutoFocus, setIsAutoFocus] = useState(false)

  useEffect(() => {
    if (!movimento && !movimentoFromCopia) {
      setIsAutoFocus(true)
    }
  }, [])

  useEffect(() => {
    if (values.causale && !values.causale_oggetto) {
      setFieldValue(
        'causale_oggetto',
        causali[values.causale as keyof typeof causali]
      )
    } else if (movimentoCopia) {
      setFieldValue(
        'causale_oggetto',
        causali[movimentoCopia.causale as keyof typeof causali]
      )
    }
  }, [values.causale, values.causale_oggetto, movimentoCopia, setFieldValue])

  useEffect(() => {
    // avoid up and down on importo field

    document.getElementById('importo')?.addEventListener('keydown', (e) => {
      if (e.which === 38 || e.which === 40) {
        e.preventDefault()
      }
    })

    document
      .getElementById('aliquota_iva')
      ?.addEventListener('keydown', (e) => {
        if (e.which === 38 || e.which === 40) {
          e.preventDefault()
        }
      })

    return () => {
      document
        .getElementById('importo')
        ?.removeEventListener('keydown', () => {})
      document
        .getElementById('aliquota_iva')
        ?.removeEventListener('keydown', () => {})
    }
  }, [])

  return (
    <>
      <Grid templateColumns="repeat(4, 2fr)" gap={4}>
        <GridItem colSpan={1} rowSpan={1}>
          <ReactSelectField
            name="causale"
            label={t('causale') ?? ''}
            id="causale"
            isAutoFocus={isAutoFocus}
            isRequired={true}
            value={
              optionsCausali.find(
                (option) => option.value === values.causale
              ) ?? undefined
            }
            onChange={(option) => {
              // reset values if causale changes
              setFieldValue('donatore', '')
              setFieldValue('fornitore', '')
              setFieldValue('bene', '')
              setFieldValue('budget', '')
              setFieldValue('progetto_destinazione', '')
              setFieldValue('modalita_pagamento', '')
              setFieldValue('conto_bancario', '')
              setFieldValue('valuta', progetto?.valuta_locale_principale)
              setFieldValue('conto_destinazione', '')
              setFieldValue('modalita_pagamento_destinazione', '')
              setFieldValue('valuta_destinazione', '')
              setFieldValue('importo_destinazione', '')
              if (!option) {
                setFieldValue('causale', undefined)
              } else {
                setFieldValue('causale', option.value)

                setFieldValue(
                  'causale_oggetto',
                  causali[option.value as keyof typeof causali]
                )
                const defaults =
                  causali[option.value as keyof typeof causali].defaults
                if (defaults.length > 0) {
                  if (defaults[0].length > 1) {
                    setFieldValue('tipo_spesa', Number(defaults[0][1]))
                  }
                }
                if (option.value === 'spesa-italia') {
                  const modalitaPagamentoBancaItalia =
                    optionsModalitaPagamento.find(
                      (option) => option.label === 'BANCA ITALIA'
                    )
                  setFieldValue(
                    'modalita_pagamento',
                    modalitaPagamentoBancaItalia?.value
                  )
                }
              }
            }}
            options={optionsCausali}
          />
        </GridItem>
        <GridItem colSpan={1} rowSpan={1}>
          <InputField
            name="numero_documento"
            isDisabled={
              values.causale_oggetto &&
              values.causale_oggetto.disabled.includes('numero_documento')
            }
            label={t('document_number') ?? ''}
            isRequired={
              values.causale_oggetto &&
              values.causale_oggetto.required.includes('numero_documento') &&
              !values.bozza
            }
          />
        </GridItem>
        <GridItem colSpan={2} rowSpan={2}>
          <TextareaField
            name="descrizione"
            rows={5}
            label={t('description') ?? ''}
            isRequired={true}
          />
        </GridItem>
        <GridItem colSpan={1}>
          <InputFileField
            name="allegato"
            label={t('attachment') ?? ''}
            isRequired={false}
          />
        </GridItem>
        <GridItem colSpan={1} rowSpan={1}>
          <AutoCompleteField
            name="movimento_anticipo"
            value={values.movimento_anticipo}
            url="/api/movimenti/options/"
            keyQuery="movimenti-options"
            params={{
              progetto: id,
              causale: 'anticipo-cassa',
            }}
            onChange={(value: any) => {
              if (!value) {
                setFieldValue('movimento_anticipo', undefined)
              } else {
                setFieldValue('movimento_anticipo', value?.value)
                setFieldValue('valuta', value?.valuta)
                setFieldValue('importo', value?.residuo_anticipo)
                setFieldValue('modalita_pagamento', value?.modalita_pagamento)
                setFieldValue('conto_bancario', value?.conto_bancario)
              }
            }}
            label={t('advance_movement') ?? ''}
            isRequired={false}
            isDisabled={
              !values.causale_oggetto ||
              values.causale_oggetto.disabled.includes('movimento_anticipo')
            }
          />
        </GridItem>
      </Grid>
      <Grid templateColumns="repeat(4, 1fr)" mt={2} gap={4}>
        <GridItem colSpan={1}>
          <DateField
            name="data_documento"
            label={t('document_date') ?? ''}
            portalId="root-portal"
            isRequired
          />
        </GridItem>
        <GridItem colSpan={1}>
          <DateField
            name="data_competenza"
            label={t('competence_date') ?? ''}
            portalId="root-portal"
            isRequired
            min={
              progetto?.data_blocco_compentenza
                ? dayjs(progetto?.data_blocco_compentenza)
                    .add(1, 'day')
                    .format('YYYY-MM-DD')
                : undefined
            }
          />
        </GridItem>
        <GridItem colSpan={1}>
          <AutoCompleteField
            name="ente"
            value={values.ente}
            url="/api/enti/options/"
            keyQuery="enti-options"
            isDisabled={
              !values.causale_oggetto ||
              values.causale_oggetto.disabled.includes('ente')
            }
            label={t('entity') ?? ''}
            isRequired={
              values.causale_oggetto &&
              values.causale_oggetto.required.includes('ente') &&
              !values.bozza
            }
          />
        </GridItem>
        <GridItem colSpan={1}>
          <InputField
            name="beneficiario"
            label={t('beneficiary') ?? ''}
            isRequired={
              values.causale_oggetto &&
              values.causale_oggetto.required.includes('beneficiario') &&
              !values.bozza
            }
            isDisabled={
              values.causale_oggetto &&
              values.causale_oggetto.disabled.includes('beneficiario')
            }
          />
        </GridItem>
      </Grid>
      <Grid templateColumns="repeat(4, 1fr)" mt={2} gap={4}>
        {(!values.causale_oggetto ||
          !values.causale_oggetto.hide.includes('fornitore')) && (
          <GridItem colSpan={1}>
            <AutoCompleteField
              name="fornitore"
              addOption={userCanAddFornitori}
              onAddOptionOpen={onOpenSearchFornitore}
              value={values.fornitore}
              url={`/api/progetti/${id}/options_fornitori/`}
              keyQuery="fornitori-options"
              isDisabled={
                !values.causale_oggetto ||
                values.causale_oggetto.disabled.includes('fornitore')
              }
              label={t('supplier') ?? ''}
              isRequired={
                values.causale_oggetto &&
                values.causale_oggetto.required.includes('fornitore') &&
                !values.bozza
              }
            />
          </GridItem>
        )}
        {(!values.causale_oggetto ||
          !values.causale_oggetto.hide.includes('donatore')) && (
          <GridItem colSpan={1}>
            <AutoCompleteField
              name="donatore"
              value={values.donatore}
              addOption={userCanGestioneAnagrafiche}
              onAddOptionOpen={onOpenDonatore}
              url={`/api/progetti/${id}/options_donatori/`}
              keyQuery="donatori-options"
              isDisabled={
                !values.causale_oggetto ||
                values.causale_oggetto.disabled.includes('donatore')
              }
              label={t('donor') ?? ''}
              isRequired={
                values.causale_oggetto &&
                values.causale_oggetto.required.includes('donatore') &&
                !values.bozza
              }
            />
          </GridItem>
        )}
        {(!values.causale_oggetto ||
          !values.causale_oggetto.hide.includes('bene')) && (
          <GridItem colSpan={1}>
            <AutoCompleteField
              name="bene"
              value={values.bene}
              addOption={userCanGestioneBeni}
              onAddOptionOpen={onOpenBene}
              params={{
                progetto: id,
              }}
              url="/api/beni/options/"
              keyQuery="beni-options"
              isDisabled={
                !values.causale_oggetto ||
                values.causale_oggetto.disabled.includes('bene')
              }
              label={t('good') ?? ''}
              isRequired={
                values.causale_oggetto &&
                values.causale_oggetto.required.includes('bene') &&
                !values.bozza
              }
            />
          </GridItem>
        )}
        {(!values.causale_oggetto ||
          !values.causale_oggetto.hide.includes('collaborazione')) && (
          <GridItem colSpan={1}>
            <AutoCompleteField
              name="collaborazione"
              value={values.collaborazione}
              addOption
              onAddOptionOpen={onOpenCollaborazione}
              url={`/api/progetti/${progetto?.id}/options-collaborazioni/`}
              keyQuery="collaborazioni-options"
              isDisabled={
                !values.causale_oggetto ||
                values.causale_oggetto.disabled.includes('collaborazione')
              }
              label={t('collaboration') ?? ''}
              isRequired={
                values.causale_oggetto &&
                values.causale_oggetto.required.includes('collaborazione') &&
                !values.bozza
              }
            />
          </GridItem>
        )}
      </Grid>
      <Grid templateColumns="repeat(4, 1fr)" mt={2} gap={4}>
        <GridItem colSpan={1}>
          <SelectChakraField
            name="tipo_spesa"
            isDisabled={
              !values.causale_oggetto ||
              values.causale_oggetto.disabled.includes('tipo_spesa')
            }
            onChange={(e) => {
              setFieldValue('tipo_spesa', Number(e.target.value))
              setFieldValue('budget', '')
            }}
            isRequired
            label={t('expense_type') ?? ''}
          >
            <option value={0}>{t('ineligible')}</option>
            <option value={1}>{t('eligible')}</option>
          </SelectChakraField>
        </GridItem>
        <GridItem colSpan={1}>
          <AutoCompleteField
            name="budget"
            value={values.budget}
            params={{
              progetto: id,
              data_budget: values.data_competenza,
            }}
            url="/api/budgets/options/"
            keyQuery="budgets-options"
            isDisabled={
              !values.causale_oggetto ||
              values.causale_oggetto.disabled.includes('budget') ||
              values.tipo_spesa === 0
            }
            label={t('budget') ?? ''}
            isRequired={
              values.tipo_spesa === 1 && values.causale_oggetto && !values.bozza
            }
          />
        </GridItem>
        <GridItem colSpan={1}>
          <SelectChakraField
            name="periodo"
            label={t('report') ?? ''}
            isRequired
          >
            <option value=""></option>
            {[...Array(numeroAnnualita)].map((_, index) => (
              <option key={index} value={index + 1}>
                {index + 1}
              </option>
            ))}
          </SelectChakraField>
        </GridItem>
        {(!values.causale_oggetto ||
          !values.causale_oggetto.hide.includes('tipo_procedura_acquisto')) && (
          <GridItem colSpan={1}>
            <ReactSelectField
              name="tipo_procedura_acquisto"
              label={t('purchase_procedure') ?? ''}
              options={TIPO_PROCEDURA_ACQUISTO_OPTIONS}
              isRequired={
                values.causale_oggetto &&
                values.causale_oggetto.required.includes(
                  'tipo_procedura_acquisto'
                ) &&
                !values.bozza
              }
              isDisabled={
                !values.causale_oggetto ||
                values.causale_oggetto.disabled.includes(
                  'tipo_procedura_acquisto'
                )
              }
              value={
                TIPO_PROCEDURA_ACQUISTO_OPTIONS.find(
                  (option) => option.value === values.tipo_procedura_acquisto
                ) ?? ''
              }
            />
          </GridItem>
        )}
      </Grid>
      <Grid templateColumns="repeat(4, 1fr)" mt={2} gap={4}>
        <GridItem colSpan={1}>
          <ReactSelectField
            name="modalita_pagamento"
            label={t('payment_method') ?? ''}
            value={
              optionsModalitaPagamento.find(
                (option) => option.value === values.modalita_pagamento
              ) ?? ''
            }
            onChange={(option) => {
              if (!option) {
                setFieldValue('modalita_pagamento', undefined)
              } else {
                setFieldValue('modalita_pagamento', option.value)
                setFieldValue('conto_bancario', undefined)
                setFieldValue('valuta', progetto?.valuta_locale_principale)
              }
            }}
            options={optionsModalitaPagamento}
            isRequired={isRequiredModalitaPagamento && !values.bozza}
            isDisabled={
              !values.causale_oggetto ||
              values.causale_oggetto.disabled.includes('modalita_pagamento') ||
              (values.causale === 'spesa-loco' && values.movimento_anticipo)
            }
          />
        </GridItem>
        <GridItem colSpan={1}>
          <AutoCompleteField
            name="conto_bancario"
            value={values.conto_bancario}
            url={`/api/progetti/${id}/options-conti-bancari/`}
            params={{
              progetto: id,
              modalita_pagamento: values.modalita_pagamento,
              valuta:
                values.valuta !== progetto?.valuta_locale_principale
                  ? values.valuta
                  : null,
            }}
            keyQuery="conti-bancari-options"
            onChange={(value: any) => {
              if (!value) {
                setFieldValue('conto_bancario', undefined)
                setFieldValue('valuta', progetto?.valuta_locale_principale)
              } else {
                setFieldValue('conto_bancario', value?.value)
                setFieldValue('valuta', value?.valuta)
              }
            }}
            isDisabled={
              !values.causale_oggetto ||
              values.causale_oggetto.disabled.includes('conto_bancario') ||
              !values.modalita_pagamento
            }
            label={
              values.causale_oggetto &&
              values.causale_oggetto.hide.includes('conto_destinazione')
                ? t('account') ?? ''
                : t('withdrawal_account') ?? ''
            }
            isRequired={values.modalita_pagamento && values.causale_oggetto}
          />
        </GridItem>
        <GridItem colSpan={1}>
          <AutoCompleteField
            isReadOnly
            name="duty_station"
            value={values.duty_station}
            url={'/api/duty-stations/options/'}
            keyQuery="duty-stations-options"
            label={t('duty_station') ?? ''}
          />
        </GridItem>
      </Grid>
      <Grid templateColumns="repeat(5, 1fr)" mt={2} gap={2}>
        <GridItem colSpan={1}>
          <ReactSelectField
            name="valuta"
            label={t('currency') ?? ''}
            options={optionsValute ?? []}
            isRequired
            onChange={(option) => {
              console.log(option)
              if (!option) {
                setFieldValue('valuta', undefined)
                setFieldValue('conto_bancario', undefined)
              } else {
                setFieldValue('conto_bancario', '')
                setFieldValue('valuta', option.value)
              }
            }}
            value={
              optionsValute.find((v) => v.value === values.valuta) ??
              optionsValute.find(
                (v) => v.value === progetto?.valuta_locale_principale
              )
            }
          />
        </GridItem>
        <GridItem colSpan={1}>
          <InputField
            name="importo"
            label={t('amount') ?? ''}
            isRequired
            type="number"
            id="importo"
          />
        </GridItem>
        <GridItem colSpan={1}>
          <InputField
            name="aliquota_iva"
            label={t('vat') + ' (%)' ?? ''}
            isRequired
            type="number"
            min="0"
            id="aliquota_iva"
          />
        </GridItem>
        <GridItem colSpan={1}>
          <InputField
            name="importo_iva"
            label={t('import_vat') ?? ''}
            isReadOnly
            type="number"
          />
        </GridItem>
        <GridItem colSpan={1}>
          <InputField
            name="importo_rendicontazione"
            label={
              t('amount_rendicontation') +
                ' (' +
                progetto?.valuta_rendicontazione_data?.codice +
                ')' ?? ''
            }
            isReadOnly
            type="number"
          />
        </GridItem>
      </Grid>
      <Grid templateColumns="repeat(4, 1fr)" mt={2} gap={4}>
        {values.causale_oggetto &&
          !values.causale_oggetto.hide.includes('progetto_destinazione') && (
            <GridItem colSpan={1}>
              <AutoCompleteField
                name="progetto_destinazione"
                value={values.progetto_destinazione}
                url="/api/progetti/options/"
                keyQuery="progetti-options"
                onChange={(value: any) => {
                  setFieldValue('progetto_destinazione', value?.value)
                  setFieldValue('conto_destinazione', undefined)
                  setFieldValue(
                    'valuta_destinazione',
                    value?.valuta_locale_principale
                  )
                }}
                isDisabled={
                  !values.causale_oggetto ||
                  values.causale_oggetto.disabled.includes(
                    'progetto_destinazione'
                  )
                }
                label={t('destination_project') ?? ''}
                isRequired={
                  values.causale_oggetto &&
                  values.causale_oggetto.required.includes(
                    'progetto_destinazione'
                  )
                }
              />
            </GridItem>
          )}
        {values.causale_oggetto &&
          !values.causale_oggetto.hide.includes(
            'modalita_pagamento_destinazione'
          ) && (
            <GridItem colSpan={1}>
              <ReactSelectField
                name="modalita_pagamento_destinazione"
                label={t('payment_method_destination') ?? ''}
                value={
                  optionsModalitaPagamento.find(
                    (option) =>
                      option.value === values.modalita_pagamento_destinazione
                  ) ?? ''
                }
                onChange={(option) => {
                  if (!option) {
                    setFieldValue('modalita_pagamento_destinazione', undefined)
                  } else {
                    setFieldValue(
                      'modalita_pagamento_destinazione',
                      option.value
                    )
                    setFieldValue('conto_destinazione', undefined)
                    setFieldValue('valuta_destinazione', undefined)
                  }
                }}
                options={optionsModalitaPagamento}
                isRequired={
                  values.causale_oggetto &&
                  values.causale_oggetto.required.includes(
                    'modalita_pagamento_destinazione'
                  )
                }
                isDisabled={
                  !values.causale_oggetto ||
                  values.causale_oggetto.disabled.includes(
                    'modalita_pagamento_destinazione'
                  ) ||
                  (!values.progetto_destinazione &&
                    values.causale !== 'cambio-valuta' &&
                    values.causale !== 'prelievo')
                }
              />
            </GridItem>
          )}
        {values.causale_oggetto &&
          !values.causale_oggetto.hide.includes('conto_destinazione') && (
            <GridItem colSpan={1}>
              <AutoCompleteField
                name="conto_destinazione"
                value={values.conto_destinazione}
                onChange={(value: any) => {
                  setFieldValue('conto_destinazione', value?.value)
                  setFieldValue('valuta_destinazione', value?.valuta)
                }}
                params={{
                  progetto: values.progetto_destinazione,
                  modalita_pagamento: values.modalita_pagamento_destinazione,
                  not_valuta:
                    values.causale === 'cambio-valuta' ? values.valuta : null,
                  valuta:
                    values.causale === 'prelievo' ||
                    values.causale === 'giroconto-chiusura' ||
                    values.causale === 'trasferimento-a-progetto'
                      ? values.valuta
                      : null,
                  not_id:
                    values.causale === 'cambio-valuta' ||
                    values.causale === 'prelievo'
                      ? values.conto_bancario
                      : null,
                }}
                url={
                  values.causale === 'cambio-valuta' ||
                  values.causale === 'prelievo'
                    ? `/api/progetti/${id}/options-conti-bancari/`
                    : `/api/progetti/${values.progetto_destinazione}/options-conti-bancari/`
                }
                keyQuery="conti-bancari-options"
                isDisabled={
                  !values.causale_oggetto ||
                  values.causale_oggetto.disabled.includes('conto_destinazione')
                }
                label={t('destination_account') ?? ''}
                isRequired={
                  values.causale_oggetto &&
                  values.causale_oggetto.required.includes('conto_destinazione')
                }
              />
            </GridItem>
          )}
        {values.causale_oggetto &&
          !values.causale_oggetto.hide.includes('valuta_destinazione') && (
            <GridItem colSpan={1}>
              <AutoCompleteField
                name="valuta_destinazione"
                value={values.valuta_destinazione}
                url={`/api/valute/options/`}
                keyQuery="valute-options"
                isDisabled={
                  !values.causale_oggetto ||
                  values.causale_oggetto.disabled.includes(
                    'valuta_destinazione'
                  )
                }
                label={t('currency_destination') ?? ''}
                isRequired={
                  values.causale_oggetto &&
                  values.causale_oggetto.required.includes(
                    'valuta_destinazione'
                  )
                }
              />
            </GridItem>
          )}
        {values.causale_oggetto &&
          !values.causale_oggetto.hide.includes('importo_destinazione') && (
            <GridItem colSpan={1}>
              <InputField
                name="importo_destinazione"
                label={t('amount_destination') ?? ''}
                isRequired={
                  values.causale_oggetto &&
                  values.causale_oggetto.required.includes(
                    'importo_destinazione'
                  )
                }
                type="number"
              />
            </GridItem>
          )}
      </Grid>
      <Grid templateColumns="repeat(4, 1fr)" mt={2} gap={4}>
        <GridItem colSpan={4}>
          <TextareaField name="note" label={t('notes') ?? ''} />
        </GridItem>
      </Grid>
      <Grid templateColumns="repeat(4, 1fr)" mt={2} gap={4}>
        <GridItem>
          <InputFileField
            name="allegato_2"
            label={t('attachment_2') ?? ''}
            isRequired={false}
          />
        </GridItem>
        <GridItem>
          <InputFileField
            name="allegato_3"
            label={t('attachment_3') ?? ''}
            isRequired={false}
          />
        </GridItem>
        <GridItem>
          <InputFileField
            name="allegato_4"
            label={t('attachment_4') ?? ''}
            isRequired={false}
          />
        </GridItem>
        <GridItem>
          <InputFileField
            name="allegato_5"
            label={t('attachment_5') ?? ''}
            isRequired={false}
          />
        </GridItem>
      </Grid>
      <Divider />
      <FieldArray
        name="progetti_copia"
        render={(arrayHelpers) => (
          <>
            <Card mt={3}>
              <CardHeader
                borderBottom={1}
                borderBottomColor={'brand'}
                bg={'brandLight'}
                py={2}
                display={'flex'}
                justifyContent={'space-between'}
                alignItems={'center'}
                fontSize={14}
                fontWeight={500}
              >
                {t('insert_into_other_project') + ' '}
                <Button
                  size={'sm'}
                  variant={'outline'}
                  colorScheme={'brand'}
                  onClick={() => {
                    arrayHelpers.push({
                      progetto: undefined,
                      budget: undefined,
                      importo: undefined,
                      aliquota_iva: 0,
                      importo_iva: 0,
                    })
                  }}
                >
                  {t('add')}
                </Button>
              </CardHeader>
              <CardBody>
                {values.progetti_copia &&
                  values.progetti_copia.length > 0 &&
                  values.progetti_copia.map((progettoCopia, i) => (
                    <Grid
                      templateColumns="repeat(11, 1fr)"
                      py={2}
                      key={i}
                      gap={2}
                      borderBottom={'1px solid'}
                      borderColor={'gray.200'}
                    >
                      <GridItem colSpan={2}>
                        <AutoCompleteField
                          name={'progetti_copia.' + i + '.progetto'}
                          label={t('project') ?? ''}
                          url={'/api/progetti/options/'}
                          params={
                            !values.causale_oggetto ||
                            values.causale_oggetto.disabled.includes(
                              'conto_bancario'
                            )
                              ? {}
                              : {
                                  conto_bancario: values.conto_bancario,
                                }
                          }
                          keyQuery="progetti-options"
                          isRequired
                          value={progettoCopia.progetto}
                        />
                      </GridItem>
                      <GridItem colSpan={2}>
                        <SelectChakraField
                          name={'progetti_copia.' + i + '.tipo_spesa'}
                          isDisabled={
                            !values.causale_oggetto ||
                            values.causale_oggetto.disabled.includes(
                              'tipo_spesa'
                            )
                          }
                          onChange={(e) => {
                            setFieldValue(
                              'progetti_copia.' + i + '.tipo_spesa',
                              Number(e.target.value)
                            )
                            setFieldValue(
                              'progetti_copia.' + i + '.budget',
                              undefined
                            )
                          }}
                          isRequired
                          label={t('expense_type') ?? ''}
                        >
                          <option value={0}>{t('ineligible')}</option>
                          <option value={1}>{t('eligible')}</option>
                        </SelectChakraField>
                      </GridItem>
                      <GridItem colSpan={2}>
                        <AutoCompleteField
                          name={'progetti_copia.' + i + '.budget'}
                          value={progettoCopia.budget}
                          params={{
                            progetto: progettoCopia.progetto,
                            data_budget: values.data_competenza,
                          }}
                          url="/api/budgets/options/"
                          keyQuery="budgets-options"
                          isDisabled={!progettoCopia.progetto}
                          label={t('budget') ?? ''}
                          isRequired={
                            values.progetti_copia &&
                            values.progetti_copia.length > 0 &&
                            values.progetti_copia[i].tipo_spesa === 1 &&
                            values.causale_oggetto
                          }
                        />
                      </GridItem>
                      <GridItem colSpan={2}>
                        <InputField
                          name={'progetti_copia.' + i + '.importo'}
                          label={t('amount') ?? ''}
                          isRequired
                          onChange={(e) => {
                            const importoIva =
                              (progettoCopia.aliquota_iva *
                                Number(e.target.value)) /
                              100
                            setFieldValue(
                              'progetti_copia.' + i + '.importo',
                              Number(e.target.value)
                            )
                            setFieldValue(
                              'progetti_copia.' + i + '.importo_iva',
                              importoIva
                            )
                          }}
                          type="number"
                        />
                      </GridItem>
                      <GridItem colSpan={1}>
                        <InputField
                          name={'progetti_copia.' + i + '.aliquota_iva'}
                          label={t('vat') ?? ''}
                          onChange={(e) => {
                            const importoIva =
                              (Number(e.target.value) * progettoCopia.importo) /
                              100
                            setFieldValue(
                              'progetti_copia.' + i + '.aliquota_iva',
                              Number(e.target.value)
                            )
                            setFieldValue(
                              'progetti_copia.' + i + '.importo_iva',
                              importoIva
                            )
                          }}
                          isRequired
                          type="number"
                          min="0"
                        />
                      </GridItem>
                      <GridItem colSpan={1}>
                        <InputField
                          name={'progetti_copia.' + i + '.importo_iva'}
                          label={t('import_vat') ?? ''}
                          isReadOnly
                          type="number"
                          min="0"
                        />
                      </GridItem>
                      <GridItem
                        colSpan={1}
                        display={'flex'}
                        alignItems={'flex-end'}
                      >
                        <Button
                          size={'sm'}
                          variant={'outline'}
                          colorScheme={'red'}
                          onClick={() => arrayHelpers.remove(i)}
                        >
                          <BiTrash />
                        </Button>
                      </GridItem>
                    </Grid>
                  ))}
              </CardBody>
            </Card>
          </>
        )}
      />
    </>
  )
}

export default function MovimentoForm({
  movimento,
  save,
  movimentoFromCopia,
  progettiCopia,
}: MovimentoFormProps) {
  const { t } = useTranslation()
  const { id } = useParams()

  const { data: movimentoCopia } = useMovimento(movimentoFromCopia)

  const initFilters = (params: URLSearchParams) => ({
    bozza: params.get('bozza') ?? movimento?.bozza ?? false,
  })

  const { filters, setFilters } = useQsFilters(initFilters)

  const {
    isOpen: isOpenFornitore,
    onOpen: onOpenFornitore,
    onClose: onCloseFornitore,
    onToggle: onToggleFornitore,
  } = useDisclosure()

  const {
    isOpen: isOpenSearchFornitore,
    onOpen: onOpenSearchFornitore,
    onClose: onCloseSearchFornitore,
    onToggle: onToggleSearchFornitore,
  } = useDisclosure()

  const {
    isOpen: isOpenDonatore,
    onOpen: onOpenDonatore,
    onClose: onCloseDonatore,
    onToggle: onToggleDonatore,
  } = useDisclosure()

  const {
    isOpen: isOpenBene,
    onOpen: onOpenBene,
    onClose: onCloseBene,
    onToggle: onToggleBene,
  } = useDisclosure()

  const {
    isOpen: isOpenCollaborazione,
    onOpen: onOpenCollaborazione,
    onClose: onCloseCollaborazione,
    onToggle: onToggleCollaborazione,
  } = useDisclosure()

  const {
    isOpen: isOpenNewCollaborator,
    onOpen: onOpenNewCollaborator,
    onClose: onCloseNewCollaborator,
    onToggle: onToggleNewCollaborator,
  } = useDisclosure()

  const MovimentoSchemaBozza = useMemo(() => {
    return Yup.object().shape({
      descrizione: Yup.string().required().label(t('description')),
      causale: Yup.string().required().label(t('causal')),
      importo: Yup.number().required().label(t('amount')),
      aliquota_iva: Yup.number().required().label(t('vat')),
      periodo: Yup.string().required().label(t('report')),
      data_competenza: Yup.string().required().label(t('competence_date')),
      data_documento: Yup.string().required().label(t('document_date')),
    })
  }, [t])

  const MovimentoSchema = useMemo(() => {
    return Yup.object().shape({
      descrizione: Yup.string().required().label(t('description')),
      causale: Yup.string().required().label(t('causal')),
      periodo: Yup.string().required().label(t('report')),
      data_competenza: Yup.string().required().label(t('competence_date')),
      data_documento: Yup.string().required().label(t('document_date')),
      numero_documento: Yup.string()
        .label(t('document_number'))
        .when('causale_oggetto', (causale_oggetto: any, schema: any) => {
          if (
            causale_oggetto &&
            causale_oggetto.length > 0 &&
            causale_oggetto[0]?.required.includes('numero_documento')
          ) {
            return schema.required()
          } else {
            return schema.notRequired()
          }
        }),
      beneficiario: Yup.string()
        .label(t('beneficiary'))
        .when('causale_oggetto', (causale_oggetto: any, schema: any) => {
          if (
            causale_oggetto &&
            causale_oggetto.length > 0 &&
            causale_oggetto[0]?.required.includes('beneficiario')
          ) {
            return schema.required()
          } else {
            return schema.notRequired()
          }
        }),
      tipo_procedura_acquisto: Yup.string()
        .label(t('purchase_procedure'))
        .when('causale_oggetto', (causale_oggetto: any, schema: any) => {
          if (
            causale_oggetto &&
            causale_oggetto.length > 0 &&
            causale_oggetto[0]?.required.includes('tipo_procedura_acquisto')
          ) {
            return schema.required()
          } else {
            return schema.notRequired()
          }
        }),
      fornitore: Yup.string()
        .label(t('supplier'))
        .when('causale_oggetto', (causale_oggetto: any, schema: any) => {
          if (
            causale_oggetto &&
            causale_oggetto.length > 0 &&
            causale_oggetto[0]?.required.includes('fornitore')
          ) {
            return schema.required()
          } else {
            return schema.notRequired()
          }
        }),
      donatore: Yup.string()
        .label(t('donor'))
        .when('causale_oggetto', (causale_oggetto: any, schema: any) => {
          if (
            causale_oggetto &&
            causale_oggetto.length > 0 &&
            causale_oggetto[0]?.required.includes('donatore')
          ) {
            return schema.required()
          } else {
            return schema.notRequired()
          }
        }),
      collaborazione: Yup.string()
        .label(t('collaboration'))
        .when('causale_oggetto', (causale_oggetto: any, schema: any) => {
          if (
            causale_oggetto &&
            causale_oggetto.length > 0 &&
            causale_oggetto[0]?.required.includes('collaborazione')
          ) {
            return schema.required()
          } else {
            return schema.notRequired()
          }
        }),
      bene: Yup.string()
        .label(t('good'))
        .when('causale_oggetto', (causale_oggetto: any, schema: any) => {
          if (
            causale_oggetto &&
            causale_oggetto.length > 0 &&
            causale_oggetto[0]?.required.includes('bene')
          ) {
            return schema.required()
          } else {
            return schema.notRequired()
          }
        }),
      ente: Yup.string()
        .label(t('entity'))
        .when('causale_oggetto', (causale_oggetto: any, schema: any) => {
          if (
            causale_oggetto &&
            causale_oggetto.length > 0 &&
            causale_oggetto[0]?.required.includes('ente')
          ) {
            return schema.required()
          } else {
            return schema.notRequired()
          }
        }),
      modalita_pagamento: Yup.string()
        .label(t('payment_method'))
        .when(
          ['causale_oggetto', 'causale', 'movimento_anticipo'],
          (values: any, schema: any) => {
            const [causale_oggetto, causale, movimento_anticipo] = values
            // if causale is spesa-loco and movimento_anticipo is not undefined then modalita_pagamento is not required
            if (causale === '' && movimento_anticipo !== undefined) {
              return schema.notRequired()
            }
            // if causale_oggetto is not undefined and modalita_pagamento is not disabled then modalita_pagamento is required
            if (
              causale_oggetto &&
              causale_oggetto?.required.includes('modalita_pagamento')
            ) {
              return schema.required()
            } else {
              return schema.notRequired()
            }
          }
        ),
      valuta: Yup.string().required().label(t('currency')),
      importo: Yup.number().required().label(t('amount')),
      modalita_pagamento_destinazione: Yup.string()
        .label(t('payment_method_destination'))
        .when('causale_oggetto', (causale_oggetto: any, schema: any) => {
          if (
            causale_oggetto &&
            causale_oggetto.length > 0 &&
            causale_oggetto[0]?.required.includes(
              'modalita_pagamento_destinazione'
            )
          ) {
            return schema.required()
          } else {
            return schema.notRequired()
          }
        }),
      progetto_destinazione: Yup.string()
        .label(t('destination_project'))
        .when('causale_oggetto', (causale_oggetto: any, schema: any) => {
          if (
            causale_oggetto &&
            causale_oggetto.length > 0 &&
            causale_oggetto[0]?.required.includes('progetto_destinazione')
          ) {
            return schema.required()
          } else {
            return schema.notRequired()
          }
        }),
      conto_destinazione: Yup.string()
        .label(t('destination_account'))
        .when('causale_oggetto', (causale_oggetto: any, schema: any) => {
          if (
            causale_oggetto &&
            causale_oggetto.length > 0 &&
            causale_oggetto[0]?.required.includes('conto_destinazione')
          ) {
            return schema.required()
          } else {
            return schema.notRequired()
          }
        }),
      valuta_destinazione: Yup.string()
        .label(t('currency_destination'))
        .when('causale_oggetto', (causale_oggetto: any, schema: any) => {
          if (
            causale_oggetto &&
            causale_oggetto.length > 0 &&
            causale_oggetto[0]?.required.includes('valuta_destinazione')
          ) {
            return schema.required()
          } else {
            return schema.notRequired()
          }
        }),
      importo_destinazione: Yup.number()
        .label(t('amount_destination'))
        .when('causale_oggetto', (causale_oggetto: any, schema: any) => {
          if (
            causale_oggetto &&
            causale_oggetto.length > 0 &&
            causale_oggetto[0]?.required.includes('importo_destinazione')
          ) {
            return schema.required()
          } else {
            return schema.notRequired()
          }
        }),
      budget: Yup.string()
        .label(t('budget'))
        .when('tipo_spesa', {
          is: 1,
          then: (schema) => schema.required(),
          otherwise: (schema) => schema.notRequired(),
        }),
      conto_bancario: Yup.string()
        .label(t('account'))
        .when('modalita_pagamento', {
          is: (val: string) => val !== undefined && val !== null && val !== '',
          then: (schema) => schema.required(),
          otherwise: (schema) => schema.notRequired(),
        }),
      progetti_copia: Yup.array()
        .of(
          Yup.object().shape({
            progetto: Yup.string().required().label(t('project')),
            budget: Yup.string()
              .label(t('budget'))
              .when('tipo_spesa', {
                is: 1,
                then: (schema) => schema.required(),
                otherwise: (schema) => schema.notRequired(),
              }),
            importo: Yup.number().required().label(t('amount')),
            aliquota_iva: Yup.number().required().label(t('vat')),
          })
        )
        .nullable(),
    })
  }, [t])

  const { data: progetto } = useProgettoSimple(Number(id!))

  const { user } = useAuthUser<AccountUser>()

  const initialVat = useMemo(() => {
    if (progetto?.paesi_intervento_data && progetto?.paesi_intervento_data[0]) {
      return progetto?.paesi_intervento_data[0].vat
    } else {
      return 0
    }
  }, [progetto])

  const initialValues = useMemo(() => {
    return {
      aliquota_iva: initialVat,
      importo_iva: 0,
      causale: '',
      causale_oggetto: undefined,
      data_competenza: progetto?.data_blocco_compentenza
        ? dayjs(progetto?.data_blocco_compentenza)
            .add(1, 'day')
            .format('YYYY-MM-DD')
        : dayjs().format('YYYY-MM-DD'),
      data_documento: dayjs().format('YYYY-MM-DD'),
      tipo_spesa: undefined,
      riportata_e_c: false,
      riportata_e_c_destinazione: false,
      periodo: undefined,
      valuta: progetto?.valuta_locale_principale || undefined,
      valuta_destinazione: undefined,
      tipo_procedura_acquisto: undefined,
      bene: undefined,
      fornitore: undefined,
      ente: progetto?.ente_principale || undefined,
      donatore: undefined,
      budget: undefined,
      collaborazione: undefined,
      modalita_pagamento: undefined,
      modalita_pagamento_destinazione: undefined,
      progetto_destinazione: undefined,
      importo_destinazione: undefined,
      conto_destinazione: undefined,
      conto_bancario: undefined,
      movimento_anticipo: undefined,
      importo: undefined,
      descrizione: '',
      bozza: filters.bozza === 'true' ? true : false,
      progetti_copia: undefined,
      movimenti_copia: undefined,
      numero_documento: '',
      beneficiario: '',
      note: '',
      duty_station: user?.permessi?.duty_station ?? undefined,
    }
  }, [
    filters.bozza,
    progetto?.ente_principale,
    initialVat,
    progetto?.valuta_locale_principale,
    user?.permessi?.duty_station,
    progetto?.data_blocco_compentenza,
  ])

  const itemsBreadCrumbCreate = useMemo(() => {
    return [
      {
        label: t('projects'),
        link: '/projects',
      },
      {
        label: progetto?.codice ?? '',
        link: `/projects/${id}`,
      },
      {
        label: t('movements'),
        link: `/projects/${id}/movements`,
      },
      {
        label: t('new_movement'),
      },
    ]
  }, [t, progetto, id])

  const itemsBreadCrumbEdit = useMemo(() => {
    return [
      {
        label: t('projects'),
        link: '/projects',
      },
      {
        label: progetto?.codice ?? '',
        link: `/projects/${id}`,
      },
      {
        label: t('movements'),
        link: `/projects/${id}/movements`,
      },
      {
        label:
          '(' +
          (movimento?.progressivo ?? 'Draft') +
          ') - ' +
          t(
            OPTIONS_CAUSALI.find(
              (causale) => causale.value === movimento?.causale
            )?.label ?? ''
          ) +
          ' - ' +
          numberFormatter.format(movimento?.importo ?? 0) +
          ' ' +
          movimento?.valuta_data?.codice,
        link: `/projects/${id}/movements/${movimento?.id}`,
      },
      {
        label: t('edit'),
      },
    ]
  }, [
    t,
    progetto?.codice,
    id,
    movimento?.progressivo,
    movimento?.importo,
    movimento?.valuta_data?.codice,
    movimento?.id,
    movimento?.causale,
  ])

  const createFornitore = useCreateFornitoreProgetto(Number(id!))
  const createDonatore = useCreateDonatoreProgetto(Number(id!))
  const createBene = useCreateBene()
  const createCollaborazione = useCreateCollaborazioneProgetto(Number(id!))
  const createCollaboratore = useCreateCollaboratore()

  const initialValuesMovimento = useMemo(() => {
    if (movimento) {
      return movimento
    } else if (movimentoFromCopia) {
      return {
        ...movimentoCopia,
        progetti_copia: progettiCopia
          ? movimentoCopia?.movimenti_copia_data?.map((movimentoCopia) => ({
              progetto: movimentoCopia.progetto,
              budget: movimentoCopia.budget,
              importo: movimentoCopia.importo,
              aliquota_iva: movimentoCopia.aliquota_iva,
              importo_iva: movimentoCopia.importo_iva,
            }))
          : undefined,
      }
    } else {
      return initialValues
    }
  }, [
    initialValues,
    movimento,
    movimentoCopia,
    movimentoFromCopia,
    progettiCopia,
  ])

  const [schema, setSchema] = useState<any>(MovimentoSchema)

  useEffect(() => {
    if (filters.bozza) {
      setSchema(MovimentoSchemaBozza)
    } else {
      setSchema(MovimentoSchema)
    }
  }, [MovimentoSchema, MovimentoSchemaBozza, filters.bozza])

  const associaProgettoFornitore = useAssociaProgettoFornitore()

  return (
    <Formik
      validationSchema={schema}
      enableReinitialize
      onSubmit={(movimento, { setErrors, setSubmitting }) => {
        save({
          ...movimento,
          progetto: Number(id!),
        }).catch((err) => {
          setErrors(transformErrorForForm(err))
          setSubmitting(false)
        })
      }}
      validateOnMount
      initialValues={initialValuesMovimento as Movimento}
    >
      {({
        handleSubmit,
        isSubmitting,
        isValid,
        handleReset,
        dirty,
        setFieldValue,
        values,
      }) => (
        <form className="form-without-scrollbar" onSubmit={handleSubmit}>
          <Flex direction={'column'}>
            <HeaderActionsPage
              sticky
              breadCrumbs={
                <BreadCrumb
                  items={
                    movimento ? itemsBreadCrumbEdit : itemsBreadCrumbCreate
                  }
                />
              }
              blockRight={
                <Box display={'flex'} alignItems={'center'}>
                  <Box me={5} display={'flex'} alignItems={'center'}>
                    <Box fontSize={'sm'} me={2}>
                      {t('draft')}
                    </Box>
                    <Switch
                      isChecked={values.bozza}
                      onChange={(e) => {
                        setFieldValue('bozza', e.target.checked)
                        setFilters({
                          ...filters,
                          bozza: e.target.checked ? true : false,
                        })
                      }}
                      colorScheme="orange"
                      size="lg"
                      mr={2}
                    />
                  </Box>
                  <Button
                    size={'sm'}
                    color={'white'}
                    isDisabled={isSubmitting}
                    type={'submit'}
                    _hover={{ bg: 'brand' }}
                    bg={'brandSecondary'}
                  >
                    {t('save')}
                  </Button>
                </Box>
              }
            />
            <Card
              mt={2}
              className={'card-scroll-height'}
              //   minHeight={'calc(100vh - 190px)'}
              overflow={'auto'}
            >
              <CardBody>
                <Grid
                  minHeight="100%"
                  templateColumns="repeat(2, 1fr)"
                  gap={4}
                ></Grid>
                <MovimentoGridForm
                  progetto={progetto}
                  onOpenBene={onOpenBene}
                  onOpenFornitore={onOpenFornitore}
                  onOpenDonatore={onOpenDonatore}
                  onOpenCollaborazione={onOpenCollaborazione}
                  onOpenSearchFornitore={onOpenSearchFornitore}
                  movimentoCopia={movimentoCopia}
                  movimento={movimento}
                  movimentoFromCopia={movimentoFromCopia}
                  progettiCopia={progettiCopia}
                />
              </CardBody>
            </Card>
          </Flex>
          <ModalSearchFornitore
            isOpen={isOpenSearchFornitore}
            onClose={onCloseSearchFornitore}
            onToggle={onToggleSearchFornitore}
            onOpenFornitore={onOpenFornitore}
            onOpen={onOpenSearchFornitore}
            progetto={progetto}
            onConfirm={async (values: any) => {
              await associaProgettoFornitore.mutateAsync({
                progetto: Number(id!),
                fornitore: values.fornitore,
              })
              setFieldValue('fornitore', values.fornitore)
              onCloseSearchFornitore()
            }}
          />
          <ModalFormFornitore
            isOpen={isOpenFornitore}
            onClose={onCloseFornitore}
            onToggle={onToggleFornitore}
            onConfirm={async (values: any) => {
              await createFornitore
                .mutateAsync({
                  ...values,
                })
                .then((res) => {
                  setFieldValue('fornitore', res.id)
                })
              onCloseFornitore()
            }}
            onOpen={onOpenFornitore}
            progetto={progetto}
          />
          <ModalFormDonatore
            isOpen={isOpenDonatore}
            onClose={onCloseDonatore}
            onToggle={onToggleDonatore}
            onConfirm={async (values: any) => {
              await createDonatore
                .mutateAsync({
                  ...values,
                })
                .then((res) => {
                  setFieldValue('donatore', res.id)
                })
              onCloseDonatore()
            }}
            onOpen={onOpenDonatore}
            progetto={progetto}
          />
          <ModalFormBene
            isOpen={isOpenBene}
            onClose={onCloseBene}
            onToggle={onToggleBene}
            onConfirm={async (values: any) => {
              await createBene
                .mutateAsync({
                  ...values,
                })
                .then((res) => {
                  setFieldValue('bene', res.id)
                })
              onCloseBene()
            }}
            onOpen={onOpenBene}
            progetto={progetto}
          />
          <ModalFormCollaboratore
            isOpen={isOpenNewCollaborator}
            onClose={onCloseNewCollaborator}
            onConfirm={async (values: any) => {
              await createCollaboratore
                .mutateAsync({
                  ...values,
                })
                .then((res) => {
                  setFieldValue('collaborazione', res.id)
                })
              onCloseNewCollaborator()
            }}
            onOpen={onOpenNewCollaborator}
            onToggle={onToggleNewCollaborator}
          />
          <ModalFormCollaborazione
            isOpen={isOpenCollaborazione}
            isOpenNewCollaborator={isOpenNewCollaborator}
            onCloseNewCollaborator={onCloseNewCollaborator}
            onOpenNewCollaborator={onOpenNewCollaborator}
            onClose={onCloseCollaborazione}
            onToggle={onToggleCollaborazione}
            onConfirm={async (values: any) => {
              await createCollaborazione
                .mutateAsync({
                  ...values,
                })
                .then((res) => {
                  setFieldValue('collaborazione', res.id)
                })
              onCloseCollaborazione()
            }}
            onOpen={onOpenCollaborazione}
            progetto={progetto}
            progettoAsField={true}
            showNewCollaboratore={true}
          />
        </form>
      )}
    </Formik>
  )
}
